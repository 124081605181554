<template lang="html">
  <Drawer
    :mask-style="{background: 'rgba(180, 183, 182, 0.6)'}"
    width="80%"
    :value="show"
    @on-visible-change="handleVisibleChange"
    class="focus-panel"
  >
    <div class="drawer-header">
      <h3 v-if="title">
        {{ title }}
      </h3>
      <div
        v-else
        class="placeholder"
      >
        <div class="title" />
      </div>
      <div class="widget-about">
        <div v-if="apiRes && apiRes.help">
          {{ apiRes.help }}
        </div>
        <div
          v-else
          class="placeholder"
        >
          <div class="text">
            <div
              v-for="i in 3"
              class="text-line"
              :key="i"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="widget-metadata"
      >
        <div>
          <p>
            {{ this.$t('global.report') }} <strong>{{ selectedPropertyName }}</strong> {{ this.$t('global.from') }}
            <strong>{{ apiRes.startDate }}</strong> {{ this.$t('global.to') }}
            <strong>{{ apiRes.endDate }}</strong>
          </p>
        </div>
        <div v-if="links.length">
          <Button
            type="primary"
            v-for="l in links"
            :key="l.format"
            :loading="isExportingFile"
            @click="exportFile(l.link)"
          >
            <Icon
              style="margin-right:5px"
              type="md-download"
            />
            {{ $t('global.dl_csv') }}
          </Button>
        </div>
      </div>

      <div
        v-else
        class="placeholder"
      >
        <div class="text">
          <div class="text-line" />
        </div>
      </div>
    </div>

    <Divider dashed />

    <div class="drawer-content">
      <Table
        class="with-header-border"
        stripe
        size="small"
        disabled-hover
        :loading="isLoading"
        :columns="columns"
        :row-class-name="rowClassName"
        :data="computedData"
      >
        <template slot="loading">
          <Spin fix>
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            />
            <div>{{ $t('global.loading') }}</div>
          </Spin>
        </template>
      </Table>
      <Page :total="data.length"
            :page-size="15"
            style="text-align: right; padding: 10px 0px 45px 0px"
            size="small"
            @on-change="page => tablePage = page"
      />
    </div>
  </Drawer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import FileDownload from 'js-file-download'
import {colsToArr, dataToArr} from '@/helpers/arrays'

export default {
  name: 'WidgetFocus',
  data () {
    return {
      tablePage: 1,
      isExportingFile: false,
      focusTimeout$: undefined,
      showDateRange: false,
      isLoading: false,
      apiErr: [],
      apiRes: {},
      title: ''
    }
  },
  computed: {
    ...mapGetters({
      scopeFocus: 'scope/focus',
      scopeSelectedProperty: 'scope/selectedProperty'
    }),
    selectedPropertyName () {
      return this.scopeSelectedProperty && this.scopeSelectedProperty.displayName
    },
    computedData () {
      const arr = this.apiRes && this.apiRes.value && this.apiRes.value.data ? dataToArr(this.apiRes.value.data, this.apiRes.value.class) : []
      const size = 15
      const page = this.tablePage
      const chunk = Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
      return chunk[page -1]
    },
    show () {
      // eslint-disable-next-line
      if (typeof this.scopeFocus === 'number') {
        return false
      }
      return !!this.scopeFocus.length
    },
    columns () {
      const cols = this.apiRes.value && this.apiRes.value ? colsToArr(this.apiRes.value.columns, 1).columns : []
      let c = cols.map(c => {
        if (c.title === 'Vendor') {
          c = {
            ...c,
            render: (h, params) => {
              if (params.row[params.column.key] !== 'Qualification in progress') {
                if (params.row[params.column.key]) {
                  return h('Tooltip', {
                    props: {
                      content: this.$t('global.graph.view_vendor'),
                      placement: 'right'
                    }
                  }, [
                    h('span', {
                      attrs: {
                        class: 'chips chips--block-item chips--green chips--action'
                      },
                      on: {
                        click: () => {
                          // console.log(cols)
                          // console.log(params.row)
                          const TargetLinkIdColIndex = cols.findIndex(el => el.title === 'TargetLinkId')
                          if (TargetLinkIdColIndex !== -1) {
                            this.clearFocus()
                            this.routerPush('Vendors view', {uuid: params.row[TargetLinkIdColIndex]})
                          } else {
                            this.clearFocus()
                            this.routerPush('Vendors view', {name: params.row[params.column.key]})
                          }
                        }
                      }
                    }, params.row[params.column.key])
                  ])
                }
              } else {
                return h('Tooltip', {
                  props: {
                    content: this.$t('global.vendor.v_under_qualification'),
                    placement: 'right'
                  }
                }, [
                  h('div', {
                    class: 'qualification-indicator'
                  }, this.$t('global.vendor.v_under_qualification'))
                ])
              }
            }
          }
        }
        return c
      })

      const TargetLinkIdColIndex = cols.findIndex(el => el.title === 'TargetLinkId')
      if (TargetLinkIdColIndex > -1) {
        c.splice(TargetLinkIdColIndex, 1)
      }

      return c
    },
    data () {
      return this.apiRes && this.apiRes.value && this.apiRes.value.data ? dataToArr(this.apiRes.value.data, this.apiRes.value.class) : []
    },
    links () {
      if (this.apiRes && this.apiRes.export) {
        const formats = Object.keys(this.apiRes.export)
        return formats.length ? formats.map(format => {
          return {format, link: this.apiRes.export[format]}
        }) : []
      } else {
        return []
      }
    },
    getExportFileName () {
      return (this.apiRes && this.apiRes.csvFileName + '.csv') || 'export.csv'
    }
  },
  methods: {
    ...mapActions({
      setFocus: 'scope/setFocus'
    }),
    clearFocus () {
      if (typeof this.focusTimeout$ !== 'undefined') {
        clearTimeout(this.focusTimeout$)
      }
      this.title = ''
      this.apiRes = {}
      this.setFocus('')
    },
    handleVisibleChange (state) {
      // Will drawer open?
      if (state) {
        // Has any URL to fetch?
        if (this.scopeFocus.length) {
          this.isLoading = true
          this.focusTimeout$ = setTimeout(() => {
            this.getFocus(this.scopeFocus)
          }, 500)
        }
      } else {
        this.clearFocus()
      }
    },
    routerPush (name, params) {
      this.$router.push({name, params})
    },
    getFocus (url) {
      this.isLoading = true
      this.$axios.get(url).then(res => {
        this.isLoading = false
        const type = typeof res.data
        if (type === 'string') {
          this.apiErr.push({type: 'warning', message: res.data})
        }
        this.apiRes = res.data.widget
        this.title = res.data.widget.title
        this.showDateRange = res.data.widget.showDateRange || true
      }).catch(err => {
        this.isLoading = false
        this.apiErr.push(err)
      })
    },
    rowClassName (row, index) {
      return row.className
    },
    async exportFile (link) {
      this.isExportingFile = true

      try {
        const response = await this.$axios.get(link,
          {
            cache: {
              exclude: {query: true}
            },
            responseType: 'blob', // Excepting a buffer stream
            headers: {
              'Accept': 'application/octet-stream'
            }
          })
        FileDownload(response.data, this.getExportFileName)
      } catch (err) {
        this.$Message.error('Server error. Please try in a few.')
        console.log(err)
      } finally {
        this.isExportingFile = false
      }
    }
  },
  destroy () {
    if (typeof this.focusTimeout$ !== 'undefined') {
      clearTimeout(this.focusTimeout$)
      this.setFocus(null)
    }
  }
}
</script>

<style lang="scss">
    .widget-focus-downloads {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
    }
</style>
