/**
 * TCF 2.x Consent String Decoder
 * https://github.com/audienceproject/tc-string-parse
 */
let str = ''
const decodeBinary = function(string) {
  let result = ''
  for (let index = 0, length = string.length; index < length; index += 1) {
    const bits = string.charCodeAt(index).toString(2)
    const pad = '00000000'.slice(0, 8 - bits.length)
    result += pad + bits
  }

  return result
}

const decodeBase64 = function(string) {
  if (typeof atob === 'function') {
    const stringFixed = string.replace(/_/g, '/').replace(/-/g, '+')

    try {
      return atob(stringFixed)
    } catch (error) {
      throw new Error('Unable to decode transparency and consent string')
    }
  }

  if (typeof Buffer === 'function') {
    return Buffer.from(string, 'base64').toString('binary')
  }

  throw new Error('Unable to detect base64 decoder')
}

const decodeInt = function(bits) {
  return parseInt(bits, 2) || 0
}

const decodeDate = function(bits) {
  return decodeInt(bits) * 100
}

const decodeString = function(bits) {
  const charOffset = 'A'.charCodeAt()
  const items = bits.match(/.{6}/g) || []
  let result = ''

  for (let index = 0, length = items.length; index < length; index += 1) {
    const charCode = decodeInt(items[index]) + charOffset
    result += String.fromCharCode(charCode)
  }

  return result
}

const decodeBoolean = function(bit) {
  return Boolean(Number(bit))
}

const decodeFlags = function(bits) {
  const items = bits.split('')
  const result = {}

  for (let index = 0, length = items.length; index < length; index += 1) {
    if (decodeBoolean(items[index])) {
      result[index + 1] = true
    }
  }

  return result
}

const getSegments = function(string) {
  if (typeof string !== 'string') {
    throw new Error('Invalid transparency and consent string specified')
  }

  const stringBlocks = string.split('.')
  const result = []

  for (let index = 0, length = stringBlocks.length; index <
  length; index += 1) {
    result.push(decodeBinary(decodeBase64(stringBlocks[index])))
  }

  const version = decodeInt(result[0].slice(0, 6))
  if (version !== 2) {
    throw new Error(
      'Unsupported transparency and consent string version "' + version +
        '"')
  }

  return result
}

const getQueue = function(segments) {
  const queuePurposes = [
    {
      key: 'purposeConsents',
      size: 24,
      decoder: decodeFlags,
    }, {
      key: 'purposeLegitimateInterests',
      size: 24,
      decoder: decodeFlags,
    }]

  const queueVendors = [
    {
      key: 'maxVendorId',
      size: 16,
    }, {
      key: 'isRangeEncoding',
      size: 1,
      decoder: decodeBoolean,
    }]

  const queueCore = [
    {
      key: 'version',
      size: 6,
    }, {
      key: 'created',
      size: 36,
      decoder: decodeDate,
    }, {
      key: 'lastUpdated',
      size: 36,
      decoder: decodeDate,
    }, {
      key: 'cmpId',
      size: 12,
    }, {
      key: 'cmpVersion',
      size: 12,
    }, {
      key: 'consentScreen',
      size: 6,
    }, {
      key: 'consentLanguage',
      size: 12,
      decoder: decodeString,
    }, {
      key: 'vendorListVersion',
      size: 12,
    }, {
      key: 'policyVersion',
      size: 6,
    }, {
      key: 'isServiceSpecified',
      size: 1,
      decoder: decodeBoolean,
    }, {
      key: 'useNonStandardStacks',
      size: 1,
      decoder: decodeBoolean,
    }, {
      key: 'specialFeatureOptins',
      size: 12,
      decoder: decodeFlags,
    }].concat(queuePurposes).concat({
    key: 'purposeOneTreatment',
    size: 1,
    decoder: decodeBoolean,
  }, {
    key: 'publisherCountryCode',
    size: 12,
    decoder: decodeString,
  }, {
    key: 'vendorConsents',
    queue: [
      {
        key: 'maxVendorId',
        size: 16,
      }, {
        key: 'isRangeEncoding',
        size: 1,
        decoder: decodeBoolean,
      }],
  }, {
    key: 'vendorLegitimateInterests',
    queue: queueVendors,
  }, {
    key: 'publisherRestrictions',
    queue: [
      {
        key: 'numPubRestrictions',
        size: 12,
      }],
  })

  const queueSegment = [
    {
      size: 3,
    }]

  const queueDisclosedVendors = [].concat(queueSegment).concat(queueVendors)

  const queueAllowedVendors = [].concat(queueSegment).concat(queueVendors)

  const queuePublisherTC = [].concat(queueSegment)
    .concat(queuePurposes)
    .concat({
      key: 'numCustomPurposes',
      size: 6,
    })

  const result = [
    {
      key: 'core',
      queue: queueCore,
    }]

  for (let index = 1; index < segments.length; index += 1) {
    const segment = segments[index]

    const type = decodeInt(segment.slice(0, 3))

    if (type === 1) {
      result.push({
        key: 'disclosedVendors',
        queue: queueDisclosedVendors,
      })
    } else if (type === 2) {
      result.push({
        key: 'allowedVendors',
        queue: queueAllowedVendors,
      })
    } else if (type === 3) {
      result.push({
        key: 'publisherTC',
        queue: queuePublisherTC,
      })
    }
  }

  return result
}

const tcStringParse = (string) => {
  str = string
  if (!string) {
    return null
  }

  try {
    const reduceQueue = function(queue, schema, value, result) {
      const reduceNumPubEntries = function() {
        if (result.pubRestrictionEntry && result.rangeEntry) {
          for (const key in result.rangeEntry) {
            if (Object.prototype.hasOwnProperty.call(result.rangeEntry, key)) {
              result.pubRestrictionEntry[key] = (result.pubRestrictionEntry[key] ||
                  []).concat(result.rangeEntry[key])
            }
          }
        }

        if (result.numPubRestrictions) {
          result.numPubRestrictions -= 1

          queue.push({
            key: 'purposeId',
            size: 6,
          }, {
            key: 'restrictionType',
            size: 2,
          }, {
            key: 'numEntries',
            size: 12,
          })
        }
      }

      const reduceNumEntries = function() {
        if (result.numEntries) {
          result.numEntries -= 1

          queue.push({
            key: 'isRange',
            size: 1,
            decoder: decodeBoolean,
          }, {
            key: 'startVendorId',
            size: 16,
          })
        } else {
          reduceNumPubEntries()
        }
      }

      const getRangeResult = function() {
        if (result.purposeId) {
          return [
            {
              purpose: result.purposeId,
              isAllowed: result.restrictionType !== 0,
              isConsentRequired: result.restrictionType === 1,
              isLegitimateInterestRequired: result.restrictionType === 2,
            }]
        }

        return true
      }

      if (schema.key === 'isRangeEncoding') {
        queue.push(value ? {
          key: 'numEntries',
          size: 12,
        } : {
          key: 'bitField',
          size: result.maxVendorId,
          decoder: decodeFlags,
        })
      } else if (schema.key === 'numEntries') {
        result.rangeEntry = {}
        reduceNumEntries()
      } else if (schema.key === 'isRange') {
        if (value) {
          queue.push({
            key: 'endVendorId',
            size: 16,
          })
        }
      } else if (schema.key === 'startVendorId') {
        if (!result.isRange) {
          result.rangeEntry[value] = getRangeResult()
          reduceNumEntries()
        }
      } else if (schema.key === 'endVendorId') {
        for (let vendorId = result.startVendorId; vendorId <=
        result.endVendorId; vendorId += 1) {
          result.rangeEntry[vendorId] = getRangeResult()
        }
        reduceNumEntries()
      } else if (schema.key === 'numCustomPurposes') {
        queue.push({
          key: 'customPurposeConsents',
          size: result.numCustomPurposes,
          decoder: decodeFlags,
        }, {
          key: 'customPurposeLegitimateInterests',
          size: result.numCustomPurposes,
          decoder: decodeFlags,
        })
      } else if (schema.key === 'numPubRestrictions') {
        result.pubRestrictionEntry = {}
        reduceNumPubEntries()
      }
    }

    const reduceResult = function(result) {
      return result.pubRestrictionEntry || result.rangeEntry ||
          result.bitField || result
    }

    let offset = 0

    const getSchemaResult = function(schema, bits) {
      const value = bits.slice(offset, offset + schema.size)
      offset += schema.size
      return (schema.decoder || decodeInt)(value)
    }

    const getSectionResult = function(sectionSchema, bits) {
      if (!sectionSchema.queue) {
        return getSchemaResult(sectionSchema, bits)
      }

      const result = {}

      for (let index = 0; index < sectionSchema.queue.length; index += 1) {
        const schema = sectionSchema.queue[index]

        const value = getSchemaResult(schema, bits)
        if (schema.key) {
          result[schema.key] = value
        }

        reduceQueue(sectionSchema.queue, schema, value, result)
      }

      return reduceResult(result)
    }

    const getBlockResult = function(blockSchema, bits) {
      const result = {}

      for (let index = 0; index < blockSchema.queue.length; index += 1) {
        const schema = blockSchema.queue[index]

        const value = getSectionResult(schema, bits)
        if (schema.key) {
          result[schema.key] = value
        }

        reduceQueue(blockSchema.queue, schema, value, result)
      }

      return reduceResult(result)
    }

    const getResult = function() {
      const segments = getSegments(string)
      const queue = getQueue(segments)

      const result = {}

      for (let index = 0; index < queue.length; index += 1) {
        const schema = queue[index]
        const bits = segments[index]

        const value = getBlockResult(schema, bits)
        if (schema.key) {
          result[schema.key] = value
        }

        offset = 0
      }
      let TC = result
      console.log(JSON.stringify(TC).length)
      if (JSON.stringify(TC).length > 400000) {
        TC = {
          error: "The decoded TC String is too large to be opened, please contact your CMP editor for further informations."
        }
      }
      return TC
    }

    return getResult()
  } catch (e) {
    return {"Error": "Invalid TC String, unable to parse"}
  }
}

export default tcStringParse