var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [[_c('Row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "lg": 10,
      "md": 10,
      "xs": 24,
      "sm": 24
    }
  }, [_c('Col', {
    staticClass: "box",
    staticStyle: {
      "height": "inherit!important"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "special-call": "getComplianceIndexDailies",
      "description": {
        title: _vm.$t('global.cookie.compliance_index'),
        desc: _vm.$t('global.cookie.compliance_index_desc')
      },
      "params": {
        properties: [this.scopeProperty.id],
        limit: 1,
        direction: 'desc',
        sort: 'ComplianceindexDailies.date'
      },
      "type": "RISK"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "lg": 14,
      "md": 14,
      "xs": 24,
      "sm": 24
    }
  }, [_c('Col', {
    staticClass: "box",
    attrs: {
      "span": "24"
    }
  }, [_c('Card', [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    },
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', [_vm._v("CMP "), _c('span', {
    staticStyle: {
      "font-size": "10px",
      "text-transform": "capitalize!important"
    }
  }, [_vm._v("(Last collects from " + _vm._s(_vm.moment(_vm.collects.created).format('LL')) + " )")])])]), _c('p', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_vm._v(" " + _vm._s(_vm.collects ? 'TCF ID #' + _vm.collects.cmp_provider_tcf_id : '') + " ")]), _vm.isLoading ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1) : _vm._e(), _vm.noData ? _c('span', {
    staticStyle: {
      "font-size": "inherit"
    }
  }, [_vm._v(_vm._s(_vm.$t('global.no_data')))]) : _vm._e(), !_vm.noData ? _c('span', {
    staticStyle: {
      "font-weight": "100",
      "font-size": "40px",
      "word-break": "break-word",
      "text-align": "center",
      "color": "#8A9190",
      "display": "block",
      "line-height": "1",
      "position": "relative"
    }
  }, [_vm._v(" " + _vm._s(_vm.collects ? '' + _vm.collects.cmp_provider_name : 'Unknown') + " "), !_vm.isDetailOpen ? _c('i', {
    staticClass: "fa fa-angle-down",
    staticStyle: {
      "position": "absolute",
      "height": "18px",
      "cursor": "pointer",
      "right": "15px",
      "top": "0px",
      "bottom": "0px",
      "margin": "auto",
      "color": "rgb(138, 145, 144)",
      "font-size": "18px"
    },
    on: {
      "click": function click($event) {
        _vm.isDetailOpen = true;
      }
    }
  }) : _c('i', {
    staticClass: "fa fa-angle-right",
    staticStyle: {
      "position": "absolute",
      "height": "18px",
      "cursor": "pointer",
      "right": "15px",
      "top": "0px",
      "bottom": "0px",
      "margin": "auto",
      "color": "rgb(138, 145, 144)",
      "font-size": "18px"
    },
    on: {
      "click": function click($event) {
        _vm.isDetailOpen = false;
      }
    }
  })]) : _vm._e(), _c('div', {
    class: {
      'open-detail': _vm.isDetailOpen
    },
    staticStyle: {
      "height": "0",
      "overflow": "hidden",
      "transition": "all 0.8s ease-in"
    }
  }, [_c('br'), _c('p', [_c('strong', [_vm._v("CMP status")])]), _c('br'), _c('br'), _c('ul', [_c('li', [_vm.collects && _vm.collects.cmp_provider_tcf_id ? _c('i', {
    staticClass: "fas fa-check-circle cmp-icon-ok"
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon-not-ok"
  }), _vm._v(" CMP registered in TCF ")]), _c('br'), _c('li', [_vm.cookieConsent.data.hasCookieConsent ? _c('i', {
    staticClass: "fas fa-check-circle cmp-icon-ok"
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon-not-ok"
  }), _vm._v(" " + _vm._s(_vm.cookieConsent.data.hasCookieConsent ? _vm.$t('global.overview.presence') : _vm.$t('global.overview.missing')) + " cookie "), _c('strong', [_vm._v("\"eu  " + _vm._s(_vm.$t('global.overview.consent')) + " \"")])])]), _c('Divider'), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.overview.tc_status')))]), _c('span', [_vm._v(" (Technical skills required, see "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.iabTcfLink
    }
  }, [_vm._v("Documentation ")]), _vm._v(" "), _c('i', {
    staticClass: "fab fa-github"
  })]), _vm._v(") "), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$t('global.overview.tc_status_desc')) + " ")])]), _c('br'), _vm.isLoading ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1) : _vm._e(), !_vm.noData && _vm.collects.scenarios.length ? _c('div', _vm._l(_vm.orderArray(_vm.collects.scenarios), function (scenar, key) {
    return _c('span', {
      key: key,
      staticStyle: {
        "margin-bottom": "10px",
        "display": "block"
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslation(scenar.scenario_key.toLowerCase())) + " : "), _vm.truncate(scenar.cmp_tc_string) === 'N/A' ? _c('span', {
      staticClass: "chips chips--purple"
    }, [_vm._v(" " + _vm._s(_vm.truncate(scenar.cmp_tc_string)) + " ")]) : _c('Tooltip', {
      attrs: {
        "trigger": "hover",
        "content": "Click to copy"
      }
    }, [_c('span', {
      staticClass: "chips chips--green",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.clickOnTc(scenar.cmp_tc_string);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.truncate(scenar.cmp_tc_string)) + " ")])]), _vm.truncate(scenar.cmp_tc_string) !== 'N/A' ? _c('Button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "type": "primary",
        "size": "small"
      },
      on: {
        "click": function click($event) {
          return _vm.clickParsed(key);
        }
      }
    }, [_vm._v("View parsed "), _vm.truncate(scenar.cmp_tc_string) !== 'N/A' ? _c('WidgetResponseModal', {
      ref: key,
      refInFor: true,
      attrs: {
        "type": 'TC',
        "urls": ['TC String'],
        "parameters": [],
        "responses": [_vm.tcDecode(scenar.cmp_tc_string)],
        "labels": ['Decoded TC String']
      }
    }) : _vm._e()], 1) : _vm._e(), _c('br')], 1);
  }), 0) : _c('span', [_vm._v(" N/A ")])], 1)], 1)], 1)], 1)], 1)], _c('WidgetFocus')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }