<template>
  <Card>
    <template slot="title">
      Articles
    </template>
    <Card
      v-for="article of articles"
      :key="article.id"
    >
      <!--      <p slot="title" class="date">{{ formatDate(article.date) }}</p>-->
      <!--      <p>-->
      <a
        :href="article.link"
        target="_blank"
      >
        {{ article.title.rendered }}
      </a>
    </Card>
  </Card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OverviewArticles',
  data () {
    return {}
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
      note: 'last articles'
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('LLL')
    }
  }
}
</script>
<style lang="scss" scope>
.ivu-card + .ivu-card {
  margin-top:16px;
}
</style>
