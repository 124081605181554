<template>
  <Card v-if="false">
    <p slot="title">
      Weekly reports
    </p>
    <tag slot="extra"
         color="blue"
    >
      New
    </tag>
    <p>Hello there, we did a rework of the notification in Facettes.<br> Now the notification are sent as a weekly report by Email <br></p>
    <br>
    <Button type="primary"
            @click="$router.push('/notifications')"
    >
      Checkout Weekly report
    </Button>
  </Card>
</template>

<script>
export default {
  name: 'OverviewActions',
  data () {
    return {

    }
  },
  props: {
  },
  methods: {
  }
}
</script>
