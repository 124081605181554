var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return false ? _c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" Weekly reports ")]), _c('tag', {
    attrs: {
      "slot": "extra",
      "color": "blue"
    },
    slot: "extra"
  }, [_vm._v(" New ")]), _c('p', [_vm._v("Hello there, we did a rework of the notification in Facettes."), _c('br'), _vm._v(" Now the notification are sent as a weekly report by Email "), _c('br')]), _c('br'), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/notifications');
      }
    }
  }, [_vm._v(" Checkout Weekly report ")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }