<template>
  <div>
    <template>
      <Row :gutter="24">
        <Col :lg="10"
             :md="10"
             :xs="24"
             :sm="24"
        >
          <Col span="24"
               style="height:inherit!important"
               class="box"
          >
            <ApiWidgetFactory
              namespace="cookie"
              special-call="getComplianceIndexDailies"
              :description="{title: $t('global.cookie.compliance_index'), desc: $t('global.cookie.compliance_index_desc')}"
              :params="{properties: [this.scopeProperty.id],limit: 1, direction: 'desc', sort: 'ComplianceindexDailies.date'}"
              type="RISK"
            />

            <!-- <WidgetTile
              api="scorevalues/scores01"
              :is-scoring="true"
              :overview="true"
              :is-actionable="true"
            /> -->
          </Col>
          <!-- <Col span="24"
               style="height:inherit!important"
               class="box"
          >

          </Col> -->
        </Col>
        <Col :lg="14"
             :md="14"
             :xs="24"
             :sm="24"
        >
          <Col span="24"
               class="box"
          >
            <Card>
              <div slot="title"
                   style="display:flex;justify-content:space-between"
              >
                <span>CMP
                  <span style="font-size:10px;text-transform: capitalize!important;">(Last collects from {{ moment(collects.created).format('LL') }} )</span>
                </span>
                <!-- <span style="text-align: right">{{ collects.cmp_provider_tcf_id ? `TCF id : ${collects.cmp_provider_tcf_id}` : '' }}</span> -->
              </div>
              <p slot="extra">
                {{ collects ? 'TCF ID #' + collects.cmp_provider_tcf_id : '' }}
              </p>
              <Spin fix
                    v-if="isLoading"
              >
                <Icon type="ios-loading"
                      size="18"
                      class="demo-spin-icon-load"
                />
                <div>{{ $t('global.loading') }}</div>
              </Spin>
              <span
                style="font-size:inherit"
                v-if="noData"
              >{{ $t('global.no_data') }}</span>
              <span v-if="!noData"
                    style=" font-weight: 100;
                            font-size: 40px;
                            word-break: break-word;
                            text-align: center;
                            color: #8A9190;
                            display: block;
                            line-height: 1;
                            position:relative"
              >
                {{ collects ? '' + collects.cmp_provider_name : 'Unknown' }}
                <i v-if="!isDetailOpen"
                   @click="isDetailOpen = true"
                   style="position:absolute; height:18px; cursor:pointer;right: 15px; top:0px;bottom:0px; margin:auto;color:rgb(138, 145, 144); font-size:18px"
                   class="fa fa-angle-down"
                />
                <i v-else
                   @click="isDetailOpen = false"
                   style="position:absolute; height:18px; cursor:pointer;right: 15px; top:0px;bottom:0px; margin:auto;color:rgb(138, 145, 144); font-size:18px"
                   class="fa fa-angle-right"
                />
              </span>

              <div :class="{'open-detail': isDetailOpen}"
                   style="height:0; overflow:hidden; transition:all 0.8s ease-in"
              >
                <br>
                <p><strong>CMP status</strong></p>
                <br>
                <br>
                <ul>
                  <li>
                    <i v-if="collects && collects.cmp_provider_tcf_id"
                       class="fas fa-check-circle cmp-icon-ok"
                    />
                    <i v-else
                       class="fas fa-times-circle cmp-icon-not-ok"
                    />
                    CMP registered in TCF
                  </li>
                  <br>
                  <li>
                    <i v-if="cookieConsent.data.hasCookieConsent"
                       class="fas fa-check-circle cmp-icon-ok"
                    />
                    <i v-else
                       class="fas fa-times-circle cmp-icon-not-ok"
                    />
                    {{
                      cookieConsent.data.hasCookieConsent ? $t('global.overview.presence') : $t('global.overview.missing')
                    }} cookie <strong>"eu&nbsp; {{ $t('global.overview.consent') }} "</strong>
                  </li>
                </ul>
                <Divider />
                <p>
                  <strong>{{ $t('global.overview.tc_status') }}</strong><span> (Technical skills required, see <a target="_blank"
                                                                                                                  :href="iabTcfLink"
                  >Documentation </a> <i class="fab fa-github" /></span>)
                  <br>
                  <span>{{ $t('global.overview.tc_status_desc') }}
                  </span>
                </p>
                <br>
                <Spin
                  fix
                  v-if="isLoading"
                >
                  <Icon
                    type="ios-loading"
                    size="18"
                    class="demo-spin-icon-load"
                  />
                  <div>{{ $t('global.loading') }}</div>
                </Spin>
                <div v-if="!noData && collects.scenarios.length">
                  <span
                    v-for="(scenar, key) of orderArray(collects.scenarios)"
                    style="margin-bottom:10px;display:block"
                    :key="key"
                  >
                    {{ getTranslation(scenar.scenario_key.toLowerCase()) }}  :
                    <span class="chips chips--purple"
                          v-if="truncate(scenar.cmp_tc_string) === 'N/A'"
                    >
                      {{ truncate(scenar.cmp_tc_string) }}
                    </span>
                    <Tooltip trigger="hover"
                             v-else
                             content="Click to copy"
                    >
                      <span
                        class="chips chips--green"
                        style="cursor:pointer"
                        @click="clickOnTc(scenar.cmp_tc_string)"
                      >
                        {{ truncate(scenar.cmp_tc_string) }}
                      </span>
                    </Tooltip>
                    <Button type="primary"
                            v-if="truncate(scenar.cmp_tc_string) !== 'N/A'"
                            style="margin-left:10px"
                            size="small"
                            @click="clickParsed(key)"
                    >View parsed
                      <WidgetResponseModal
                        :ref="key"
                        v-if="truncate(scenar.cmp_tc_string) !== 'N/A'"
                        :type="'TC'"
                        :urls="['TC String']"
                        :parameters="[]"
                        :responses="[tcDecode(scenar.cmp_tc_string)]"
                        :labels="['Decoded TC String']"
                      /></Button>
                    <br></span>
                </div>
                <span v-else> N/A </span>
              </div>
            </Card>
          </Col>
        </Col>
      </Row>
    </template>
    <WidgetFocus />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import WidgetResponseModal from '@/components/ui/WidgetResponseModal'
import TCStringDecoder from '@/helpers/TCSringDecoder'
import WidgetFocus from '@/components/ui/WidgetFocus'
import WidgetTile from '@/components/ui/WidgetTile'
import moment from 'moment'
import * as _ from 'lodash'

export default {
  name: 'CMPOverview',
  components: {
    WidgetFocus,
    // WidgetTile,
    WidgetResponseModal,
    ApiWidgetFactory
  },
  data () {
    return {
      moment: moment,
      isDetailOpen: false,
      collapse: false,
      collects: {
        data: []
      },
      exempted_non_declared_vendors: 0,
      iabTcfLink: 'https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20Consent%20string%20and%20vendor%20list%20formats%20v2.md',
      tcDecode: TCStringDecoder,
      cmpVendors: {
        cmpName: 'No data',
        pagination: {
          count: 'No data'
        }
      },
      declaredCmpVendors: {
        value: 'No data',
        link: ''
      },
      noData: true,
      cookieConsent: {
        data: {
          hasCookieConsent: false
        }
      },
      isLoading: true
    }
  },
  mounted () {
    this.getRequestsData()
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs',
      scopeProperty: 'scope/selectedProperty',
      scopeDaterange: 'scope/selectedDaterange'
    })
  },
  watch: {
    scopeQs (val, old) {
      if (val && val !== old) {
        this.getRequestsData()
      }
    }
  },
  methods: {
    ...mapActions({
      scopeSetFocus: 'scope/setFocus'
    }),
    getTranslation(scenario) {
      const i18nkeys = {
        no_user_choice: "global.scenarios.without_choice",
        consent_to_all: "global.scenarios.with_choice",
        refuse_to_all: "global.scenarios.refusal_choice"
      }
      return this.$t(i18nkeys[scenario])
    },
    clickParsed (key) {
      this.$refs[key][0].modalOpen = true
    },
    clickOnTc (tcString) {
      var el = document.createElement('textarea')
      el.value = tcString
      el.setAttribute('readonly', '')
      el.style = {position: 'absolute', left: '-9999px'}
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      return this.$Message.info('TC String copied on clipboard')
    },
    truncate(input = '') {
      if (!input) return 'N/A'
      if (input.length > 35) {
        return input.substring(0, 35) + '...'
      }
      return input
    },
    focus (link = '') {
      if (link.length) this.scopeSetFocus(link)
    },
    redirectVendorsOutCmp () {
      this.$router.push({name: 'Vendors list', params: {cmp: false}})
    },
    orderArray(arr) {
      // Replace 1 array element at index with item
      // const a1 = arr[1]
      // const a2 = arr[2]
      // arr.splice(1,1,a1)
      return [
        arr.find(elm => elm.scenario_outcome === 'NO_ACTION'),
        arr.find(elm => elm.scenario_outcome === 'ACCEPT'),
        arr.find(elm => elm.scenario_outcome === 'REJECT')
      ].filter(elm => !!elm)
    },
    mergeCollects (collects) {
      let collect = {
        scenarios: null
      }
      collects.forEach(obj => {
        collect = _.mergeWith({}, obj, collect,
          (a, b) => b === null ? a : undefined
        )
        // collect = Object.assign(collect, obj)
      })
      return collect
    },
    // TODO: Fix ce truc tout pourris
    getRequestsData () {
      this.isLoading = true
      this.noData = true
      const apiList = [
        {
          url: '/api/vendors?',
          params: {
            onlyDetected: true,
            properties: [this.scopeProperty.id],
            period: this.scopeDaterange,
            limit: 1,
            CMP: 'NOT_FOUND'
          }
        },
        {
          url: '/api/cookies/hasCookieConsent',
          params: {
            properties: [this.scopeProperty.id],
            period: this.scopeDaterange
          }
        },
        // {
        //   url: '/api/CollectCmpvendorlists/getConsentProvider?',
        //   params: {
        //     properties: [this.scopeProperty.id],
        //     period: this.scopeDaterange
        //   }
        // },
        {
          url: '/api/collects',
          params: {
            properties: [this.scopeProperty.id],
            scenarios: true,
            sort: 'Collects.created',
            direction: 'DESC',
            limit: 1
          }
        }
      ]
      const fetch = (item) => this.$axios.get(item.url, {
        params: {
          ...item.params
        },
        cache: {
          exclude: {query: !this.useCache}
        }
      }).then(resp => {
        if (resp.data.success) {
          return resp.data
        } else {
          this.noData = true
          this.isLoading = false
        }
      }).catch(er => {
        this.noData = true
        this.isLoading = false
      })
      Promise.all(apiList.map(url => fetch(url))).then(resp => {
        this.cmpVendors = resp[0]
        this.cookieConsent = resp[1]
        this.collects = this.mergeCollects(resp[2].data)
        // this.cmpVendors.cmpName = resp[2].data.provider.name
        // this.collects = this.mergeCollects(resp[3].data)
        this.isLoading = false
        this.noData = false
      }).catch(er => {
        this.isLoading = false
        this.$Message.warning('Error fetching API')
      })
    }
  }
}
</script>
<style lang="scss">
.open-detail {
  height:390px !important
}
.cmp-icon-ok {
  font-size: 20px;
  vertical-align: middle;
  color: #6eba3e
}

.cmp-icon-not-ok {
  font-size: 20px;
  vertical-align: middle;
  color: #d7215f
}
</style>
