var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "index-main"
  }, [_c('br'), !_vm.qs || !(_vm.userHasRole('super-admin') || _vm.userHasRole('support') || _vm.userHasRole('vendors-admin') || _vm.userHasRole('vendors-viewer') || _vm.userHasRole('evidence-viewer') || _vm.userHasRole('publisher-fp')) ? _c('NoScope') : [_c('Row', {
    attrs: {
      "type": "flex",
      "justify": "space-between",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "lg": 24,
      "md": 24,
      "sm": 24
    }
  }, [_c('DailyOverview', {
    ref: "cmp"
  })], 1)], 1), _c('br'), _c('br'), _vm.qs && _vm.daterange[0] !== _vm.daterange[1] ? _c('h3', {
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Metrics for selected period :')) + " "), _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.daterange[0]).format('LL') + ' - ' + _vm.moment(_vm.daterange[1]).format('LL')))])]) : _vm._e(), _c('h4', {
    staticStyle: {
      "color": "#333"
    }
  }, [_vm._v(" Vendors ")]), _c('br'), _c('Row', {
    attrs: {
      "gutter": 24,
      "type": "flex",
      "justify": "space-between",
      "align": "top"
    }
  }, [_c('Col', {
    attrs: {
      "lg": 24,
      "md": 24,
      "sm": 24
    }
  }, [_vm.qs ? [_c('Row', {
    attrs: {
      "type": "flex",
      "justify": "space-between",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "description": {
        title: _vm.$t('global.overview.declared_vendors_cmp'),
        title2: _vm.$t('global.scenarios.refusal_choice'),
        desc: _vm.$t('global.vendor.count_desc')
      },
      "force-endpoint": 'wvendors/declaredCMPVendorsCount',
      "params": {
        properties: [_vm.property.id],
        startdate: _vm.daterange[0],
        enddate: _vm.daterange[1]
      },
      "custom-click-function": {
        method: _vm.focus
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('All active vendors'),
        title2: _vm.$t('global.scenarios.refusal_choice'),
        desc: _vm.$t('global.vendor.count_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1
      },
      "redirect": '/vendors',
      "second-params": {
        cmpScenarioOutcomes: ['REJECT'],
        limit: 1,
        onlyDetected: true
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('Actives vendors non declared in CMP'),
        title2: _vm.$t('Included exempted vendors'),
        desc: _vm.$t('global.vendor.count_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1,
        CMP: 'NOT_FOUND'
      },
      "redirect": '/vendors/vendors-management',
      "second-params": {
        onlyDetected: true,
        is_exempted: true,
        CMP: 'NOT_FOUND',
        limit: 1
      },
      "type": "ONE_VALUE"
    }
  })], 1)], 1), _c('br'), _c('br'), _c('h4', {
    staticStyle: {
      "color": "#333"
    }
  }, [_vm._v(" Trackers ")]), _c('br'), _c('Row', {
    attrs: {
      "type": "flex",
      "justify": "space-between",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('global.cookie.all_detected'),
        desc: _vm.$t('global.cookie.all_detected')
      },
      "params": {
        limit: 1
      },
      "redirect": '/trackers',
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('Trackers dropped without explicit choice'),
        desc: _vm.$t('global.cookie.all_detected')
      },
      "params": {
        limit: 1,
        cmpScenarioOutcomes: ['NO_ACTION']
      },
      "redirect": ['/trackers', '#nochoice'],
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('Trackers dropped despite refusal'),
        desc: _vm.$t('global.cookie.all_detected')
      },
      "params": {
        limit: 1,
        cmpScenarioOutcomes: ['REJECT']
      },
      "redirect": ['/trackers', '#despite'],
      "type": "ONE_VALUE"
    }
  })], 1)], 1)] : _vm._e()], 2)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }