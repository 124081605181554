var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', [_c('template', {
    slot: "title"
  }, [_vm._v(" Articles ")]), _vm._l(_vm.articles, function (article) {
    return _c('Card', {
      key: article.id
    }, [_c('a', {
      attrs: {
        "href": article.link,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(article.title.rendered) + " ")])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }