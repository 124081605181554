<template>
  <main class="index-main">
    <!-- <h1
      v-if="qs"
      orientation="left"
    >
      {{ $t('global.overview.last_collected_data') }} <strong>{{ moment(property.lastIngest).format('LL') }}</strong>
    </h1> -->
    <br>
    <NoScope v-if="!qs ||
      !(userHasRole('super-admin')
        || userHasRole('support')
        || userHasRole('vendors-admin')
        || userHasRole('vendors-viewer')
        || userHasRole('evidence-viewer')
        || userHasRole('publisher-fp')
      ) "
    />
    <template v-else>
      <Row
        type="flex"
        justify="space-between"
        :gutter="24"
      >
        <Col
          :lg="24"
          :md="24"
          :sm="24"
        >
          <DailyOverview ref="cmp" />
        </Col>
      </Row>


      <br><br>
      <h3 v-if="qs && daterange[0] !== daterange[1]"
          orientation="left"
      >
        {{ $t('Metrics for selected period :') }} <strong>{{ moment(daterange[0]).format('LL') + ' - ' + moment(daterange[1]).format('LL') }}</strong>
      </h3>

      <h4 style="color:#333">
        Vendors
      </h4>
      <br>
      <Row
        :gutter="24"
        type="flex"
        justify="space-between"
        align="top"
      >
        <Col
          :lg="24"
          :md="24"
          :sm="24"
        >
          <template v-if="qs">
            <Row
              type="flex"
              justify="space-between"
              :gutter="24"
            >
              <Col :lg="8"
                   :md="8"
                   :xs="24"
                   :sm="24"
              >
                <ApiWidgetFactory
                  :description="{title: $t('global.overview.declared_vendors_cmp'), title2: $t('global.scenarios.refusal_choice'), desc: $t('global.vendor.count_desc')}"
                  :force-endpoint="'wvendors/declaredCMPVendorsCount'"
                  :params="{
                    properties: [property.id],
                    startdate: daterange[0],
                    enddate: daterange[1]
                  }"
                  :custom-click-function="{
                    method: focus
                  }"
                  type="ONE_VALUE"
                />
              </Col>
              <!-- <Col :lg="4"
                   :md="4">
               <Card>
                  <span slot="title">{{ $t('global.overview.declared_vendors_cmp') }}</span>
                  <Spin
                    fix
                    v-if="isLoading"
                  >
                    <Icon
                      type="ios-loading"
                      size="18"
                      class="demo-spin-icon-load"
                    />
                    <div>{{ $t('global.loading') }}</div>
                  </Spin>
                  <span
                    v-if="declaredCmpVendors !== null"
                    class="big-number big-number--action"
                  ><a @click="focus(declaredCmpVendors.link)">{{ declaredCmpVendors.value }}</a></span>
                  <span v-else> {{ $t('global.overview.no_cmp_data') }} </span>
                </Card>
              </Col> -->
              <Col
                :lg="8"
                :md="8"
                :xs="24"
                :sm="24"
              >
                <ApiWidgetFactory
                  namespace="vendor"
                  :description="{title: $t('All active vendors'), title2: $t('global.scenarios.refusal_choice'), desc: $t('global.vendor.count_desc')}"
                  :params="{onlyDetected: true, limit: 1}"
                  :redirect="'/vendors'"
                  :second-params="{cmpScenarioOutcomes: ['REJECT'], limit: 1, onlyDetected: true}"
                  type="ONE_VALUE"
                />
              </Col>
              <Col
                :lg="8"
                :md="8"
                :xs="24"
                :sm="24"
              >
                <ApiWidgetFactory
                  namespace="vendor"
                  :description="{title: $t('Actives vendors non declared in CMP'), title2: $t('Included exempted vendors'), desc: $t('global.vendor.count_desc')}"
                  :params="{onlyDetected: true, limit: 1, CMP:'NOT_FOUND'}"
                  :redirect="'/vendors/vendors-management'"
                  :second-params="{onlyDetected: true, is_exempted: true, CMP:'NOT_FOUND', limit: 1}"
                  type="ONE_VALUE"
                />
              </Col>
            </Row>
            <br>
            <br>
            <h4 style="color:#333">
              Trackers
            </h4>
            <br>
            <Row
              type="flex"
              justify="space-between"
              :gutter="24"
            >
              <Col :lg="8"
                   :md="8"
                   :xs="24"
                   :sm="24"
              >
                <ApiWidgetFactory
                  namespace="cookie"
                  :description="{title: $t('global.cookie.all_detected'), desc: $t('global.cookie.all_detected')}"
                  :params="{limit: 1}"
                  :redirect="'/trackers'"
                  type="ONE_VALUE"
                />
              </Col>
              <Col :lg="8"
                   :md="8"
                   :xs="24"
                   :sm="24"
              >
                <ApiWidgetFactory
                  namespace="cookie"
                  :description="{title: $t('Trackers dropped without explicit choice'), desc: $t('global.cookie.all_detected')}"
                  :params="{limit: 1, cmpScenarioOutcomes: ['NO_ACTION']}"
                  :redirect="['/trackers', '#nochoice']"
                  type="ONE_VALUE"
                />
              </Col>
              <Col :lg="8"
                   :md="8"
                   :xs="24"
                   :sm="24"
              >
                <ApiWidgetFactory
                  namespace="cookie"
                  :description="{title: $t('Trackers dropped despite refusal'), desc: $t('global.cookie.all_detected')}"
                  :params="{limit: 1, cmpScenarioOutcomes: ['REJECT']}"
                  :redirect="['/trackers', '#despite']"
                  type="ONE_VALUE"
                />
              </Col>
            </Row>
          </template>
        </Col>
      </Row>
    </template>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OverviewActions from './OverviewActions'
import OverviewArticles from './OverviewArticles'
import DailyOverview from './DailyOverview'
import WidgetFocus from '@/components/ui/WidgetFocus'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import WidgetTile from '@/components/ui/WidgetTile'
import NoScope from '@/components/ui/NoScope'
import moment from 'moment'

export default {
  name: 'Overview',
  components: {
    NoScope,
    ApiWidgetFactory,
    // OverviewActions,
    // OverviewArticles,
    DailyOverview,
    // WidgetTile
  },
  data () {
    return {
      link: '',
      moment: moment,
      trigger: true,
      propertyVendors: {},
      cookieDetails: '',
      vendorsDetails: '',
      propertyCookies: {},
      declaredCmpVendors: null,
      benchmarkApi: {
        cookies: 'wcookies/cookiesCountForSimilarProperties',
        vendors: 'wvendors/vendorsCountForSimilarProperties'
      },
      dimention: 'vendors',
      dimentionList: [{ value: 'vendors' }, { value: 'cookies' }],
      nbProperties: [{ value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }],
      nbPropertiesCheck: 10,
      isLoading: true,
      myBarChart: null,
      defaultBarOptions: {
        borderWidth: 1,
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2
      },
      nbArticles: 3,
      articles: [],
      barData: {
        labels: [],
        datasets: [{
          label: 'vendors',
          backgroundColor: ['#1CA08D', '#556893', '#556893', '#556893', '#556893'],
          data: [],
          minBarLength: 20,
          borderWidth: 1,
          barThickness: 80
        }]
      }
    }
  },
  mounted () {
    //this.fetchWpArticles(this.nbArticles)
    const params = {
      userId: this.user.id,
      properties: this.properties.map(property => property.id)
    }
    this.isLoading = true
    // return this.getRegisteredNotifs({params, uid: this._uid}).then(r => {
    //   this.$store.state.notifications.registeredNotification = r.data
    //   this.isLoading = false
    // })
  },
  watch: {
    $route (to, from){
      const route = this.$route
      if (this.$route.path === '/') {
        console.log('trigger = true')
        this.trigger = true
        return false
      }
      this.trigger = false
    },
    // qs: {
    //   handler: function (old, val) {
    //     this.getDeclaredCMPVendors()
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      qs: 'scope/qs',
      user: 'user/user',
      userHasRole: 'user/hasRole',
      property : 'scope/selectedProperty',
      properties: 'scope/properties',
      daterange: 'scope/selectedDaterange'
    })
  },
  methods: {
    download () {
      window.open(this.link, '_blank')
    },
    ...mapActions({
      scopeSetFocus: 'scope/setFocus',
      setDetails: 'scope/setOverviewWidgetDetails',
      getRegisteredNotifs: 'notifications/getRegisteredNotifs'
    }),
    stateVendors (vendors) {
      this.propertyVendors = vendors
    },
    stateCookies (cookies) {
      this.propertyCookies = cookies
    },
    updateDateRange () {
      if (this.qs) {
        this.$refs.cmp && this.$refs.cmp.getRequestsData()
      }
    },
    focus (link = '') {
      if (link.length) this.scopeSetFocus(link)
    },
    // getDeclaredCMPVendors () {
    //   this.isLoading = true
    //   this.declaredCmpVendors = null
    //   const api =         {
    //     url: 'wvendors/declaredCMPVendorsCount', // Do not touch for now.
    //     params: {
    //       properties: [this.property.id],
    //       startdate: this.daterange[0],
    //       enddate: this.daterange[1]
    //     }
    //   }
    //   return this.$axios(api.url, {params: {
    //     ...api.params
    //   },
    //   cache: {
    //     exclude: {query: false}
    //   }})
    //     .then( r => {
    //       this.declaredCmpVendors = r.data.widget
    //       this.isLoading = false
    //     })
    // },
    // fetchWpArticles (nb) {
    //   // tuto: cat 38
    //   const url = 'https://agnostik.fr/wp-json/wp/v2/posts?per_page=' + nb + '&categories=36'
    //   return this.$axios.get(url)
    //     .then(r => {
    //       this.articles = r.data
    //     })
    //     .catch(er => {
    //       this.$Message.warning('error fetching articles')
    //     })
    // }
  }
}
</script>
<style lang="scss">
.ivu-divider-horizontal.ivu-divider-with-text-center:before, .ivu-divider-horizontal.ivu-divider-with-text-left:before, .ivu-divider-horizontal.ivu-divider-with-text-right:before, .ivu-divider-horizontal.ivu-divider-with-text-center:after, .ivu-divider-horizontal.ivu-divider-with-text-left:after, .ivu-divider-horizontal.ivu-divider-with-text-right:after {
  border-top: 1px solid #333!important;
}
</style>
